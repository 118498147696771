import React from 'react'

type Props = {
  condition: boolean
  wrapper: (children: React.ReactNode) => React.ReactChild
  children: React.ReactChild
}

export function ConditionalWrap({
  condition,
  wrapper,
  children,
}: Props): React.ReactElement {
  return <>{condition ? wrapper(children) : children}</>
}
