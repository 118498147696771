import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { WordmarkQuery } from '../../types/graphql'

type ImgExcludeSrc = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>
type Props = {} & ImgExcludeSrc

const query = graphql`
  query WordmarkQuery {
    datoCmsCompanyInformation {
      wordmark {
        url
      }
    }
  }
`

export const Wordmark: React.FC<Props> = ({ ...restProps }) => {
  const data = useStaticQuery<WordmarkQuery>(query)

  const { wordmark } = data.datoCmsCompanyInformation!
  return (
    <img
      src={wordmark!.url!}
      alt="PT. Unggul Semesta Wordmark"
      {...restProps}
    />
  )
}
