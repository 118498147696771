import { css } from '@emotion/core'
import React from 'react'
import { useCompleteNestedMenuData } from '../../config/navigation-menu'
import { useUI } from '../../store/ui'
import { NestedMenu } from '../NestedMenu'

export const mobileNavId = 'ac-mobile-nav'

export const MobileNav: React.FC = () => {
  const { state } = useUI()
  const data = useCompleteNestedMenuData()

  return (
    <div
      id={mobileNavId}
      aria-hidden={!state.isMobileNavShown}
      role="dialog"
      aria-label="Slide out menu"
      className="md:hidden fixed inset-0 z-mobileNav w-4/5 h-screen max-w-sm overflow-hidden bg-white shadow-lg ui-transition"
      css={css`
        overscroll-behavior-y: contain;

        &[aria-hidden='true'] {
          transform: translateX(-100%);
        }
      `}
    >
      <nav>
        <NestedMenu title="Main Menu" menuItems={data} />
      </nav>
    </div>
  )
}
