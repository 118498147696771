import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { PhoneQuery } from '../../types/graphql'
import { PhoneLink } from '../Links'

type Props = {
  withAlternate?: boolean
}

const query = graphql`
  query PhoneQuery {
    datoCmsCompanyInformation {
      phone
      phoneAlternate
    }
  }
`

export function Phone({ withAlternate = false }: Props): React.ReactElement {
  const data = useStaticQuery<PhoneQuery>(query)
  const { phone, phoneAlternate } = data.datoCmsCompanyInformation!
  return (
    <>
      <PhoneLink phone={phone!}>{phone}</PhoneLink>

      {withAlternate && (
        <PhoneLink phone={phoneAlternate!}>{phoneAlternate}</PhoneLink>
      )}
    </>
  )
}
