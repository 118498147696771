import { css } from '@emotion/core'
import React from 'react'
import { useUI } from '../../store/ui'
import { FeatherIcon } from '../FeatherIcon'
import { mobileNavId } from './MobileNav'

export const MobileNavToggle: React.FC = () => {
  const { state, dispatch } = useUI()

  return (
    <button
      type="button"
      onClick={() => dispatch({ type: 'toggleMobileNav' })}
      aria-label="Menu"
      aria-expanded={state.isMobileNavShown}
      aria-controls={mobileNavId}
      className="md:hidden fixed z-mobileNavToggle p-2 -mr-2 border-none"
      css={css`
        top: 0.75rem;
        right: 5%;

        &[aria-expanded='true'] {
          color: var(--color-gray-300);
        }
      `}
    >
      <FeatherIcon name={state.isMobileNavShown ? 'x' : 'menu'} />
    </button>
  )
}
