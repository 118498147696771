import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { AddressQuery } from '../../types/graphql'
import { ConditionalWrap } from '../ConditionalWrap'
import { ExternalLink } from '../Links'

type Props = {
  asLink?: boolean
}

const query = graphql`
  query AddressQuery {
    datoCmsCompanyInformation {
      addressNode {
        childMarkdownRemark {
          html
        }
      }
      googleMapsLink
    }
  }
`

export function Address({ asLink = false }: Props): React.ReactElement {
  const data = useStaticQuery<AddressQuery>(query)
  const { addressNode, googleMapsLink } = data.datoCmsCompanyInformation!

  return (
    <ConditionalWrap
      condition={asLink}
      wrapper={children => (
        <ExternalLink href={googleMapsLink!}>{children}</ExternalLink>
      )}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: addressNode!.childMarkdownRemark!.html!,
        }}
      />
    </ConditionalWrap>
  )
}
