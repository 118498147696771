import React from 'react'
import { useUI } from '../../store/ui'
import { cx } from '../../utils/helpers'

type Props = {}

export const Overlay: React.FC<Props> = () => {
  const { state, dispatch } = useUI()

  return (
    <div
      onClick={() => dispatch({ type: 'toggleMobileNav' })}
      className={cx(
        'md:hidden fixed inset-0 z-overlay bg-gray-900 ui-transition',
        state.isMobileNavShown ? 'opacity-75' : 'opacity-0 pointer-events-none',
      )}
    />
  )
}
