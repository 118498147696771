import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { desktopMenu } from '../../config/navigation-menu'
import { setPartiallyCurrent } from '../../utils/helpers'
import { WidthWrapper } from '../WidthWrapper'

const navHeadingId = 'main-nav-heading'

export function Nav(): ReactElement {
  return (
    <nav aria-labelledby={navHeadingId} className="hidden md:block">
      <WidthWrapper>
        <h2 id={navHeadingId} aria-hidden className="sr-only">
          Main Navigation
        </h2>

        <ul className="flex justify-around -mx-4">
          {desktopMenu.map(({ id, text, href }) => (
            <li key={id}>
              <Link
                to={href}
                getProps={setPartiallyCurrent}
                className="block px-4 py-3"
                css={css`
                  :hover,
                  &[data-partially-current='true'],
                  &[aria-current='page'] {
                    color: var(--color-accent);
                  }
                `}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </WidthWrapper>
    </nav>
  )
}
