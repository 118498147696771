import { MenuItem } from '../types'

type Page =
  | 'home'
  | 'products'
  | 'careers'
  | 'about'
  // | 'groupCompanies'
  | 'contact'

export const pages: Record<Page, MenuItem> = {
  home: {
    id: 'home',
    text: 'Home',
    href: '/',
  },
  products: {
    id: 'products',
    text: 'Products',
    href: '/products/',
  },
  careers: {
    id: 'careers',
    text: 'Careers',
    href: '/careers/',
  },
  about: {
    id: 'about',
    text: 'About',
    href: '/about/',
  },
  contact: {
    id: 'contact',
    text: 'Contact',
    href: '/contact/',
  },
  // groupCompanies: {
  //   id: 'group-companies',
  //   text: 'Group',
  //   href: '/group-companies/',
  // },
}
