import { graphql, useStaticQuery } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React from 'react'
import Helmet from 'react-helmet'
import { HtmlHeadQuery } from '../../types/graphql'

const query = graphql`
  query HtmlHeadQuery {
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
  }
`

export const HtmlHead: React.FC = () => {
  const data = useStaticQuery<HtmlHeadQuery>(query)
  const { faviconMetaTags } = data.datoCmsSite!

  return (
    <>
      <Helmet>
        <html lang="en-US" />
        {/* TODO: Minimum viable sharing meta tags */}
        {/* Check with richpreview.com */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
        />
      </Helmet>

      {/* favicon checker: https://realfavicongenerator.net/favicon_checker */}
      <HelmetDatoCms favicon={faviconMetaTags} />
    </>
  )
}
