import React, { createContext, useReducer, useContext } from 'react'

const initialState = {
  isMobileNavShown: false,
  isNotificationShown: false,
  notificationText: '',
}

type State = typeof initialState
type Action =
  | {
      type: 'toggleMobileNav'
    }
  | {
      type: 'sendNotification'
      text: string
    }
  | {
      type: 'dismissNotification'
    }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'toggleMobileNav':
      return {
        ...state,
        isMobileNavShown: !state.isMobileNavShown,
      }
    case 'sendNotification':
      return {
        ...state,
        isNotificationShown: true,
        notificationText: action.text,
      }
    case 'dismissNotification':
      return {
        ...state,
        isNotificationShown: false,
      }
    default:
      const _exhaustiveCheck: never = action
      return _exhaustiveCheck
  }
}

type Context = {
  state: State
  dispatch: React.Dispatch<Action>
}

const UIContext = createContext<Context>({
  state: initialState,
  dispatch: () => {},
})

export const UIStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <UIContext.Provider value={{ state, dispatch }}>
      {children}
    </UIContext.Provider>
  )
}

export function useUI() {
  return useContext(UIContext)
}
