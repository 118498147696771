import React from 'react'
import '../../assets/fonts/loader.css'
import '../../css/style.css'
import { UIStoreProvider } from '../../store/ui'
import { Footer } from './Footer'
import { Header } from './Header'
import { HtmlHead } from './HtmlHead'
import { MobileNav } from './MobileNav'
import { MobileNavToggle } from './MobileNavToggle'
import { Overlay } from './Overlay'

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <HtmlHead />

      <UIStoreProvider>
        <Header />
        <MobileNavToggle />
        <MobileNav />
        {children}
        <Footer />
        <Overlay />
      </UIStoreProvider>
    </>
  )
}

export default Layout
