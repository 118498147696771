import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { footerNavItems } from '../../config/navigation-menu'
import { Address } from '../company-info/Address'
import { Email } from '../company-info/Email'
import { Logomark } from '../company-info/Logomark'
import { Phone } from '../company-info/Phone'
import { Wordmark } from '../company-info/Wordmark'
import { WidthWrapper } from '../WidthWrapper'

export function Footer(): ReactElement {
  return (
    <footer className="mt-16 bg-white border-t border-gray-300">
      <WidthWrapper
        className="pt-8"
        css={css`
          display: grid;
          grid-gap: 2rem 4rem;
          grid-template-areas:
            'company-intro'
            'company-info'
            'footer-nav';

          @media (min-width: 640px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
              'company-intro company-intro'
              'company-info footer-nav';
          }

          /* @media (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
              'company-intro footer-nav'
              'company-info footer-nav';
          } */

          @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas: 'company-intro company-info footer-nav';
          }
        `}
      >
        <CompanyIntro
          style={{
            gridArea: 'company-intro',
          }}
        />
        <CompanyInfo
          style={{
            gridArea: 'company-info',
          }}
        />
        <FooterNav
          style={{
            gridArea: 'footer-nav',
          }}
        />
      </WidthWrapper>

      <div className="text-center text-xs mt-8 text-gray-700 py-2 border-t bg-gray-100 border-gray-300">
        Copyright PT. Unggul Semesta. All rights reserved.
      </div>
    </footer>
  )
}

function CompanyIntro(
  props: React.HTMLAttributes<HTMLElement>,
): React.ReactElement {
  return (
    <section {...props}>
      <div className="flex items-center w-64 mb-8">
        <Logomark className="w-1/4" />
        <Wordmark
          style={{ marginBottom: '-2%' }}
          className="w-3/4 ml-fluid-md"
        />
      </div>

      <div
        className="text-sm leading-snug text-gray-700"
        css={css`
          p {
            margin-top: var(--space-2);
          }
        `}
      >
        <p>Over 30 years of experience supplying welding equipments.</p>

        <p>
          Best-in-class products and services for welding, press, material
          handling, and automation.
        </p>
      </div>
    </section>
  )
}

function CompanyInfo(
  props: React.HTMLAttributes<HTMLElement>,
): React.ReactElement {
  return (
    <section {...props}>
      <h2 className="text-sm font-bold">Contact Information</h2>

      <dl
        css={css`
          dt {
            margin-top: var(--space-4);
            color: var(--color-gray-700);
            font-size: var(--text-xs);
          }

          dd {
            margin-top: var(--space-1);
            font-size: var(--text-sm);
            line-height: var(--leading-tight);
          }

          a {
            :hover {
              color: var(--color-accent);
            }
          }
        `}
      >
        <dt>Phone</dt>
        <dd>
          <Phone />
        </dd>

        <dt>Email</dt>
        <dd>
          <Email />
        </dd>

        <dt>Address</dt>
        <dd
          css={css`
            p + p {
              margin-top: var(--space-1);
            }
          `}
        >
          <Address />
        </dd>
      </dl>
    </section>
  )
}

function FooterNav(
  props: React.HTMLAttributes<HTMLElement>,
): React.ReactElement {
  return (
    <nav {...props}>
      <h2 className="text-sm font-bold">Navigation</h2>

      <ul className="mt-4">
        {footerNavItems.map(({ id, text, href, childItems }) => {
          return (
            <li key={id}>
              <Link to={href} className="block py-1 text-sm hover:text-accent">
                {text}
              </Link>

              {childItems && (
                <ul>
                  {childItems.map(
                    ({ id: childId, text: childText, href: childHref }) => (
                      <li
                        key={childId}
                        className="pl-4"
                        css={css`
                          position: relative;

                          ::before {
                            content: '';
                            display: block;
                            position: absolute;
                            left: 1px;
                            top: 2px;
                            height: 14px;
                            width: 10px;
                            border: solid 1px var(--color-gray-300);
                            border-bottom-left-radius: var(--rounded-sm);
                            border-top: 0;
                            border-right: 0;
                          }
                        `}
                      >
                        <Link
                          to={childHref}
                          className="block py-1 text-sm hover:text-accent"
                        >
                          {childText}
                        </Link>
                      </li>
                    ),
                  )}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
