import { css } from '@emotion/core'
import React from 'react'

type Props = React.HTMLAttributes<HTMLElement> & {
  as?: 'div' | 'article' | 'section'
}

export const WidthWrapper: React.FC<Props> = ({
  children,
  as: Tag = 'div',
  className,
  ...restProps
}) => {
  return (
    <Tag
      className={className}
      css={css`
        --content-width: var(--max-w-6xl);
        --padding: var(--space-fluid-md);

        max-width: calc(var(--content-width) + var(--padding) * 2);
        padding-left: var(--padding);
        padding-right: var(--padding);
        margin-left: auto;
        margin-right: auto;
      `}
      {...restProps}
    >
      {children}
    </Tag>
  )
}
