import featherSprite from 'feather-icons/dist/feather-sprite.svg'
import React from 'react'

type Props = React.SVGAttributes<SVGElement> & {
  name: string
  color?: string
  size?: number
}

export const FeatherIcon: React.FC<Props> = ({
  name,
  color = 'currentColor',
  size = 24,
  ...restProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...restProps}
    >
      <use xlinkHref={`${featherSprite}#${name}`} />
    </svg>
  )
}
