import { graphql, useStaticQuery } from 'gatsby'
import { FooterNavItem, MenuItem, NestedNullable } from '../types'
import { BrandSubcategoryTreeQuery } from '../types/graphql'
import { getBrandSubcategoryTree, resolvePath } from '../utils/dato'
import { pages } from './pages-metadata'

interface TopLevelMenuItem extends MenuItem {
  isProductsIndex?: boolean
}

export const desktopMenu: TopLevelMenuItem[] = [
  pages.products,
  pages.careers,
  pages.about,
  pages.contact,
]

const mobileMenu: TopLevelMenuItem[] = [
  pages.home,
  {
    ...pages.products,
    isProductsIndex: true,
  },
  pages.careers,
  pages.about,
  pages.contact,
]

export const footerNavItems: FooterNavItem[] = [
  pages.home,
  pages.products,
  pages.careers,
  pages.about,
  pages.contact,
]

const query = graphql`
  query BrandSubcategoryTreeQuery {
    datoCmsProductsPage {
      content {
        categoryTitle
        slug
        brands {
          name
          slug
          business
          brandSubcategoryEntry {
            ...BrandSubcategoryTreeFragment
          }
        }
      }
    }
  }
`

function getTopLevelCategorySecondaryText(
  // this weird null checks are for Apollo generated types
  brands: NestedNullable<{ name: string }[]>,
): string {
  if (!brands || !brands.length) {
    return ''
  }

  switch (brands.length) {
    case 1:
      return brands[0]!.name!
    case 2:
      return `${brands[0]!.name} and ${brands[1]!.name}`
    case 3:
      return `${brands[0]!.name}, ${brands[1]!.name}, and ${brands[2]!.name}`
    default:
      return `${brands[0]!.name}, ${brands[1]!.name}, and ${brands.length -
        2} other`
  }
}

export function useProductsNestedMenuData(): MenuItem[] {
  const data = useStaticQuery<BrandSubcategoryTreeQuery>(query)

  if (
    !data.datoCmsProductsPage ||
    !data.datoCmsProductsPage.content ||
    !data.datoCmsProductsPage.content.length
  ) {
    throw new Error(
      'Products Page must be published and contain at least 1 Top Level Category.',
    )
  }

  // Products has child items of Top Level Categories
  const productsChildItems = data.datoCmsProductsPage.content.map<MenuItem>(
    topLevelCategory => {
      if (
        !topLevelCategory ||
        !topLevelCategory.slug ||
        !topLevelCategory.categoryTitle
      ) {
        throw new Error('Invalid Top Level Category entry.')
      }

      if (!topLevelCategory.brands || !topLevelCategory.brands.length) {
        throw new Error(
          `Top Level Category must contain at least 1 brand. ` +
            `Check: ${topLevelCategory.categoryTitle}.`,
        )
      }

      return {
        id: `top-level-category-${topLevelCategory.slug}`,
        text: topLevelCategory.categoryTitle,
        secondaryText: getTopLevelCategorySecondaryText(
          topLevelCategory.brands,
        ),
        href: `/products/#${topLevelCategory.slug}`,
        // Top Level Categories have child items of Brands
        childItems: topLevelCategory.brands.map<MenuItem>(brand => {
          if (!brand || !brand.name || !brand.slug || !brand.business) {
            throw new Error('Invalid Brand entry.')
          }

          if (
            !brand.brandSubcategoryEntry ||
            !brand.brandSubcategoryEntry.treeChildren ||
            !brand.brandSubcategoryEntry.treeChildren.length
          ) {
            throw new Error(
              `Brand must have entry to Brand Subcategory. Check: ${brand.name}.`,
            )
          }

          return {
            id: `brand-${brand.slug}`,
            text: brand.name,
            secondaryText: brand.business,
            href: resolvePath({
              kind: 'brand',
              slug: brand.slug,
            }),
            // Brands have child items of Brand Subcategories (recursive)
            childItems: getBrandSubcategoryTree(
              brand.brandSubcategoryEntry.treeChildren,
              brand.slug,
            ),
          }
        }),
      }
    },
  )

  return productsChildItems
}

export function useCompleteNestedMenuData(): MenuItem[] {
  const productsChildItems = useProductsNestedMenuData()

  const mainMenuWithProductsTree = mobileMenu.map<MenuItem>(menu => {
    if (menu.isProductsIndex) {
      return {
        ...menu,
        childItems: productsChildItems,
      }
    }

    return menu
  })

  return mainMenuWithProductsTree
}
