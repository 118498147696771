import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { LogomarkQuery } from '../../types/graphql'

type ImgExcludeSrc = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>
type Props = {} & ImgExcludeSrc

const query = graphql`
  query LogomarkQuery {
    datoCmsCompanyInformation {
      logomark {
        url
      }
    }
  }
`

export const Logomark: React.FC<Props> = ({ ...restProps }) => {
  const data = useStaticQuery<LogomarkQuery>(query)

  const { logomark } = data.datoCmsCompanyInformation!
  return (
    <img
      src={logomark!.url!}
      alt="PT. Unggul Semesta Logomark"
      {...restProps}
    />
  )
}
