import { Link } from 'gatsby'
import React from 'react'
import { Logomark } from '../company-info/Logomark'
import { Wordmark } from '../company-info/Wordmark'
import { WidthWrapper } from '../WidthWrapper'
import { Nav } from './Nav'

export const Header: React.FC = () => {
  return (
    <header>
      <WidthWrapper>
        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <Link to="/" className="block w-56 py-2 flex items-center">
            <Logomark
              className="w-1/4"
              alt="PT. Unggul Semesta Logomark home"
            />
            <Wordmark
              // optical adjustment
              style={{ marginBottom: '-2%' }}
              className="w-3/4 ml-2"
              alt="PT. Unggul Semesta Wordmark home"
            />
          </Link>

          <Nav />
        </div>
      </WidthWrapper>
    </header>
  )
}
