import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { EmailQuery } from '../../types/graphql'
import { MailtoLink } from '../Links'

type Props = {}

const query = graphql`
  query EmailQuery {
    datoCmsCompanyInformation {
      email
    }
  }
`

export const Email: React.FC<Props> = () => {
  const data = useStaticQuery<EmailQuery>(query)

  const { email } = data.datoCmsCompanyInformation!
  return <MailtoLink email={email!}>{email}</MailtoLink>
}
