import { MenuItem, NestedNullable } from '../types'

type ResolvePathArgs =
  | {
      kind: 'brand'
      slug: string
    }
  | {
      kind: 'brandSubcategory'
      brandSlug: string
      slug: string
    }
  | {
      kind: 'product'
      brandSlug: string
      slug: string
    }
  | {
      kind: 'job'
      slug: string
    }

/*
 * Cleaner path resolver, use this if you want to be explicit
 */
export function resolvePath(arg: ResolvePathArgs): string {
  switch (arg.kind) {
    case 'brand':
      return `/brand/${arg.slug}/`
    case 'brandSubcategory':
      return `/brand/${arg.brandSlug}/subcategory/${arg.slug}/`
    case 'product':
      return `/brand/${arg.brandSlug}/product/${arg.slug}/`
    case 'job':
      return `/careers/${arg.slug}/`
    default:
      const _exhaustiveCheck: never = arg
      return _exhaustiveCheck
  }
}

type ResolvePathByDatoModelArgs =
  | {
      __typename: 'DatoCmsPage'
    } & NestedNullable<{ slug: string }>
  | {
      __typename: 'DatoCmsBrand'
    } & NestedNullable<{ slug: string }>
  | {
      __typename: 'DatoCmsBrandSubcategory'
    } & NestedNullable<{
      slug: string
      brand: {
        slug: string
      }
    }>
  | {
      __typename: 'DatoCmsProduct'
    } & NestedNullable<{
      slug: string
      brand: {
        slug: string
      }
    }>

/*
 * Dirtier path resolver, for union model types straight out of GraphQL
 */
export function resolvePathByDatoModel(
  arg: ResolvePathByDatoModelArgs,
): string {
  switch (arg.__typename) {
    case 'DatoCmsPage':
      return `/${arg.slug}/`
    case 'DatoCmsBrand':
      if (!arg.slug) {
        throw new Error('slug doesn’t exist')
      }
      return resolvePath({
        kind: 'brand',
        slug: arg.slug,
      })
    case 'DatoCmsBrandSubcategory':
      if (!arg.slug || !arg.brand || !arg.brand.slug) {
        throw new Error('slug doesn’t exist')
      }
      return resolvePath({
        kind: 'brandSubcategory',
        slug: arg.slug,
        brandSlug: arg.brand.slug,
      })
    case 'DatoCmsProduct':
      if (!arg.slug || !arg.brand || !arg.brand.slug) {
        throw new Error('slug doesn’t exist')
      }
      return resolvePath({
        kind: 'product',
        slug: arg.slug,
        brandSlug: arg.brand.slug,
      })
    default:
      const _exhaustiveCheck: never = arg
      return _exhaustiveCheck
  }
}

type BrandSubcategory = NestedNullable<{
  name: string
  slug: string
  treeChildren: BrandSubcategory[]
}>

function traverseBrandSubcategoryTree(
  brandSubcategory: BrandSubcategory,
  brandSlug: string,
): MenuItem {
  const { name, slug, treeChildren } = brandSubcategory

  const id = slug ? `${brandSlug}-${slug}` : ''
  const text = name || ''
  const href = slug
    ? resolvePath({
        kind: 'brandSubcategory',
        slug,
        brandSlug,
      })
    : ''
  const childItems =
    treeChildren && treeChildren.length > 0
      ? treeChildren.map<MenuItem>(child => {
          if (!child) {
            throw new Error(
              'Please ensure all entries in Brand Subcategory tree is valid',
            )
          }

          return traverseBrandSubcategoryTree(child, brandSlug)
        })
      : undefined

  return {
    id,
    text,
    href,
    childItems,
  }
}

export function getBrandSubcategoryTree(
  brandsubCategories: Array<BrandSubcategory | null>,
  brandSlug: string,
): MenuItem[] {
  return brandsubCategories.map(brandSubcategory => {
    if (!brandSubcategory) {
      throw new Error('Invalid Brand Subcategory entry.')
    }

    return traverseBrandSubcategoryTree(brandSubcategory, brandSlug)
  })
}
