import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useUI } from '../store/ui'
import { MenuItem } from '../types'
import { FeatherIcon } from './FeatherIcon'

type NestedMenuProps = {
  id?: string
  onBackClick?: () => void
  isExpanded?: boolean
  title: string
  menuItems: MenuItem[]
}

function getNestedMenuId(id: string) {
  return `ac-nm-${id}`
}

export const NestedMenu: React.FC<NestedMenuProps> = ({
  id,
  isExpanded,
  title,
  menuItems,
  onBackClick,
}) => {
  const nestedMenuId = id && getNestedMenuId(id)

  return (
    <section
      id={nestedMenuId}
      // so that top level menu won’t have aria-hidden attribute at all
      aria-hidden={isExpanded === undefined ? undefined : !isExpanded}
      className="bg-gray-100 absolute h-screen top-0 inset-x-0 overflow-hidden ui-transition"
      css={css`
        will-change: transform;

        /* by default, all the nested menu containers are offscreen */
        &[aria-hidden='true'] {
          transform: translateX(100%);
        }
      `}
    >
      <div
        // momentum scrolling causes visual bug on iOS landscape
        style={{ overscrollBehaviorY: 'contain' }}
        className="h-screen overflow-y-auto scrolling-touch"
      >
        <div className="pb-32">
          <header
            style={{
              top: '0.25rem',
            }}
            className="sticky bg-white border-b-2 border-brand mx-2 mt-1 rounded shadow-lg px-6 py-4"
          >
            <div className="-mx-2 flex items-center">
              {onBackClick && (
                <button
                  type="button"
                  aria-controls={nestedMenuId}
                  onClick={onBackClick}
                  style={{
                    // optical adjustment
                    marginLeft: '-0.9rem',
                  }}
                  className="p-2 -my-2 mr-2 border-none"
                >
                  <FeatherIcon name="chevron-left" size={20} />
                </button>
              )}

              <h2
                // optical adjustment with the back button
                style={{ marginTop: '-2px' }}
                className="text-center truncate text-xl"
              >
                {title}
              </h2>
            </div>
          </header>

          <ul role="menubar" className="mt-2">
            {menuItems.map(
              ({ id: itemId, text, secondaryText, href, childItems }) => (
                <li key={itemId} role="none">
                  <NestedMenuItem
                    id={itemId}
                    text={text}
                    secondaryText={secondaryText}
                    href={href}
                    childItems={childItems}
                  />
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </section>
  )
}

const NestedMenuItem: React.FC<MenuItem> = ({
  id,
  text,
  secondaryText,
  href,
  childItems,
}) => {
  const { dispatch } = useUI()
  const [isExpanded, setExpanded] = useState(false)
  const hasChildItems = childItems && childItems.length > 0

  return (
    <>
      {/* ESLint warns aria-expanded isn’t supported by role menuitem */}
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <Link
        to={href}
        role="menuitem"
        aria-haspopup={hasChildItems ? 'tree' : undefined}
        aria-controls={hasChildItems ? getNestedMenuId(id) : undefined}
        aria-expanded={hasChildItems ? isExpanded : undefined}
        onClick={
          hasChildItems
            ? event => {
                event.preventDefault()
                setExpanded(true)
              }
            : () => dispatch({ type: 'toggleMobileNav' })
        }
        className="block px-6 hover:text-accent"
      >
        <div className="py-4 flex justify-between items-center border-gray-200 border-b">
          <div className="flex flex-col overflow-hidden">
            <span
              // optical adjustment
              style={{ marginTop: '-2px' }}
              className="truncate"
              css={css`
                [aria-current='page'] & {
                  color: var(--color-accent);
                }
              `}
            >
              {text}
            </span>
            {secondaryText && (
              <span className="text-sm text-gray-700 truncate">
                {secondaryText}
              </span>
            )}
          </div>
          {hasChildItems && (
            <span style={{ marginRight: '-0.4rem' }} className="ml-2">
              <FeatherIcon name="chevron-right" size={20} />
            </span>
          )}
        </div>
      </Link>

      {childItems && childItems.length > 0 && (
        <NestedMenu
          id={id}
          isExpanded={isExpanded}
          menuItems={[
            {
              id: `overview-${id}`,
              text,
              secondaryText: 'Overview',
              href,
            },
            ...childItems,
          ]}
          onBackClick={() => setExpanded(false)}
          title={text}
        />
      )}
    </>
  )
}
