// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-brand-tsx": () => import("./../src/templates/brand.tsx" /* webpackChunkName: "component---src-templates-brand-tsx" */),
  "component---src-templates-brand-subcategory-tsx": () => import("./../src/templates/brand-subcategory.tsx" /* webpackChunkName: "component---src-templates-brand-subcategory-tsx" */),
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-job-tsx": () => import("./../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

